import { apiMethods } from '../../infrastructure/api';
import { saveActiveDeals } from '../../infrastructure/store';

export const useGetActiveDeals = () => {
	const getActiveDeals = apiMethods.useGetActiveDeals();

	return async () => {
		const result = await getActiveDeals();

		if (result.isRight()) {
			saveActiveDeals(result.value);
		}
	};
};
