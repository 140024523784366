<script setup lang="ts">
import { useSocketRatesData } from '../../terminal/infrastructure/store';
import { useGetActiveDeals } from '../core/application';
import { getActiveDealsSections } from '../core/domain';
import { useActiveDeals } from '../infrastructure/store';
import ActiveDealsSection from './ActiveSection.vue';

const getActiveDeals = useGetActiveDeals();

const socketRatesData = useSocketRatesData();
const activeDeals = useActiveDeals();
const activeDealsSections = computed(() => getActiveDealsSections(activeDeals.value, socketRatesData.value));

getActiveDeals();
</script>

<template>
	<div
		v-if="activeDeals.length"
		class="flex flex-col gap-[14px]"
	>
		<ActiveDealsSection
			v-for="activeSection in activeDealsSections"
			:key="activeSection.asset + activeSection.stringifiedTime"
			:section="activeSection"
		/>
	</div>
</template>
