import dayjs from 'dayjs';
import type { Deals, Deal, DealsSections } from '../types';
import { getDealSectionType } from './getDealItemType';
import type { ChartRateItem } from '~/src/features/terminal/types';

const getSectionKey = (item: Deal) => {
	const type = getDealSectionType(item);
	const expirationTimestamp = new Date(item.expirationDate).getTime();
	const expirationMinute = Math.ceil(expirationTimestamp / 60000);

	return `${item.asset}__${type}__${item.percent}__${expirationMinute}`;
};

const getSectionSum = (items: Deals): number => {
	return items.reduce((acc, item) => acc + item.sum, 0);
};

const getSectionIncome = (items: Deals): number => {
	return items.reduce((acc, item) => acc + item.income, 0);
};

const getMapItems = (items: Deals): Record<string, Deals> => {
	const map: Record<string, Deals> = {};

	items.forEach((item) => {
		const key = getSectionKey(item);

		if (key in map) {
			map[key].push(item);
		}
		else {
			map[key] = [item];
		}
	});

	return map;
};

export const getDealsSections = (items: Deals): DealsSections => {
	// const getSectionResultSum = (items: Deals): DealsSection['resultSum'] =>
	// 	items.reduce((acc, item) => acc + item.resultSum, 0);

	const map = getMapItems(items);

	const sections: DealsSections = Object.values(map).map(items => ({
		sum: getSectionSum(items),
		income: getSectionIncome(items),
		// TODO ???
		// resultSum: getSectionResultSum(items),
		resultSum: getSectionIncome(items),
		asset: items[0].asset,
		items,
		percent: items[0].percent,
		stringifiedTime: dayjs(items[0].startDate).format('HH:mm'),
		type: getDealSectionType(items[0]),
	}));

	return sections;
};

export const getActiveDealsSections = (items: Deals, socketRatesData: Record<string, ChartRateItem>): DealsSections => {
	console.log(socketRatesData);
	Object.entries(socketRatesData).forEach(([key, rateItem]) => {
		console.log(key, rateItem);
	});

	return getDealsSections(items);
};
