import type { Deals } from '../../core/types';
import type { ClosedDealsResponse } from './getClosedDeals';
import { useApi } from '@/src/services/network';
import type { Response } from '~/src/shared/lib';

export type ActiveDealsResponse = ClosedDealsResponse;

export type ActiveDealsDTO = Response<ClosedDealsResponse>;

export const toDomain = (data: ActiveDealsDTO): Deals =>
	data.result.items.map(item => ({
		id: item.id,
		startDate: item.date,
		expirationDate: item.expiration_date,
		asset: item.trading_asset.name,
		percent: item.asset_percent,
		sum: item.sum,
		income: item.income,
		resultSum: item.expiration_asset_price,
		startSum: item.asset_price,
		type: item.bet_dynamics.code,
	}));

export const useGetActiveDeals = () => {
	const api = useApi();

	return async () =>
		(await api<ActiveDealsDTO>('/DealsHistory/getActive/')).mapRight(toDomain);
};
