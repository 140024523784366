import { saveOpenedDealsSection } from '../../infrastructure/store';
import type { DealsSection } from '../types';
import { pageDrawers, PAGE_DRAWERS } from '~/src/features/page-drawers';

export const useOpenDealsSection = () => {
	return (section: DealsSection) => {
		saveOpenedDealsSection(section);
		pageDrawers.open(PAGE_DRAWERS.detailDeal, {
			nested: true,
		});
	};
};
