<script setup lang="ts" generic="T extends string">
import { BaseIcon } from '@legacy-studio/library';
import { getDrawerIndex, hasPrevDrawer, isDrawerOpen } from '../core/utils';

const props = withDefaults(
	defineProps<{
		title: string;
		fullscreen?: boolean;
		name: T;
		useDrawerNames: () => ComputedRef<Array<T>>;
		direction?: 'left' | 'right';
	}>(),
	{
		direction: 'left',
		fullscreen: false,
	},
);

const emit = defineEmits<{
	close: [];
	back: [];
}>();

const drawersNames = props.useDrawerNames();

const close = () => {
	emit('close');
};

const back = () => {
	emit('back');
};

const isOpen = computed(() => isDrawerOpen(props.name, drawersNames.value));

const withBack = computed(() => hasPrevDrawer(props.name, drawersNames.value));

const index = computed(() => getDrawerIndex(props.name, drawersNames.value));
</script>

<template>
	<Transition name="widget">
		<div
			v-if="isOpen"
			class="bottom-0 top-16 absolute md:flex-none overflow-y-auto bg-gray-800 flex flex-col gap-4 p-4 overflow-x-hidden"
			:class="{
				'md:w-[320px]': !fullscreen,
				'left-0 md:left-[60px]':
					direction === 'left' || (fullscreen && direction === 'right'),
				'right-0':
					direction === 'right' || (fullscreen && direction === 'left'),
				'border-l border-gray-600 md:border-l-0 md:border-r':
					direction === 'left',
				'border-r border-gray-600 md:border-r-0 md:border-l':
					direction === 'right',
			}"
			:style="{
				zIndex: Math.max(index, 0) + 60,
			}"
		>
			<header class="flex justify-between">
				<button
					v-if="withBack"
					type="button"
					@click="back"
				>
					<BaseIcon
						name="base--fill--arrow-narrow-left"
						class="text-white text-[18px]"
					/>
				</button>

				<span class="text-base font-semibold text-white">
					{{ title }}
				</span>

				<button
					type="button"
					@click="close"
				>
					<BaseIcon
						name="base--fill--x"
						class="text-gray-400 text-[18px]"
					/>
				</button>
			</header>

			<div class="flex flex-col gap-8">
				<slot />
			</div>
		</div>
	</Transition>
</template>

<style>
.widget-enter-active,
.widget-leave-active {
  transition: opacity 0.2s ease;
}

.widget-enter-active {
  transition: all 0.1s;
}

.widget-leave-active {
  transition: all 0.1s;
}

.widget-enter-from,
.widget-leave-to {
  transform: translateX(50%);
  opacity: 0;
}
</style>
