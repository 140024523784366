import { apiMethods } from '../../infrastructure/api';
import { saveActiveInvoice, saveInvoices } from '../../infrastructure/store';

export const useGetInvoices = () => {
	const getInvoices = apiMethods.useGetInvoices();

	return async () => {
		const result = await getInvoices();

		if (result.isRight()) {
			saveInvoices(result.value);
			saveActiveInvoice(result.value[0]);
		}
	};
};
